.modal-edit-billing-request {

    .modal-content {
        display: flex;
        flex-direction: row;

        ap-billing-request-recap-order,
        ap-billing-request-recap-quote,
        ap-billing-request-recap-line {
            width: 100%;
            display: block;
        }

        .display-quotes {
            width: 25%;
            z-index: 2;
            min-width: 300px;
        }

        .edit-content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .form-container {
                display: grid;
                grid-template-rows: repeat(2, 1fr);
                grid-template-columns: repeat(3, 16%) auto;

                gap: 10px;


                #type-container {
                    grid-area: 1 / 1 / 2 / 2;
                }

                #quotes-container {
                    grid-area: 1 / 2 / 2 / 3;
                }

                #date-container {
                    grid-area: 1 / 3 / 2 / 4;
                }

                #designation-container {
                    grid-area: 2 / 1 / 3 / 4;
                }

                #comment-container {
                    grid-area: 1 / 4 / 3 / 5;

                    display: flex;
                    flex-direction: column;

                    textarea {
                        flex-grow: 1;
                    }
                }

            }

            .line {
                display: grid;
                grid-template-columns: minmax(60%, 800px) 40%;
                grid-template-rows: 50px;
                gap: 10px;

                align-items: center;

                border-radius: 5px;

                font-size: 12px;
                cursor: pointer;

                --color-text: #959595;
                --color-price: #000;

                &.error {

                    .col-right,
                    .col-left {
                        border-radius: 5px;
                        border: 1px solid red !important;
                    }

                    --color-text : red !important;
                    --color-price : red !important;

                    input {
                        color: red;
                    }
                }

                &.title {
                    cursor: default;

                    font-weight: 700;
                    font-size: 16px;
                }

                &.sub-title {
                    cursor: default;

                    font-weight: 700;
                    font-size: 16px;

                    margin-top: 40px;

                    .col-left {
                        color: #8C8C8C;
                    }
                }

                &.sum {
                    cursor: default;

                    &:not(.loading) {

                        .col-left,
                        .col-right {
                            border-radius: 5px;
                            background: #EEEEEE;
                        }
                    }

                    --color-text: #1D1D1D;

                    .text {
                        font-weight: 700;
                    }

                }

                &.line-price {

                    .text {
                        color: var(--color-text) !important;
                        flex-grow: 1;
                    }

                    .col-left {
                        display: grid;
                        grid-template-columns: 1fr 65px 1fr 1fr;
                        align-items: center;
                        gap: 1vw;
                        padding: 0 10px;
                        height: 100%;

                        >div {
                            display: flex;
                            flex-wrap: wrap;

                            .price {
                                color: var(--color-price);
                                font-weight: 700;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }

                    .col-right {
                        display: grid;
                        grid-template-columns: minmax(5ch, 60px) minmax(90px, 130px) minmax(90px, 130px) 80px;
                        align-items: center;
                        gap: 1vw;
                        padding: 0 10px;
                        height: 100%;
                    }

                    &.selected {
                        background-color: #F5F9FF;
                        position: relative;

                        &::before {
                            content: "‹";
                            font-weight: bold;
                            position: absolute;
                            left: -10px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            transform: scale(2.5);
                            top: -2px;
                        }
                    }
                }

                &.line-border {
                    grid-template-rows: 10px;

                    .col-left,
                    .col-right {
                        border-bottom: 1px solid #eaeaea;
                    }
                }

            }

        }
    }

}
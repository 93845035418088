.page-card {

    display: flex;
    flex-direction: column;
    background: white;
    font-size: 0.9rem;
    border-radius: 10px;
    box-shadow: #0000001a 0px 1px 2px 0px;

    padding: 10px;
    gap: 10px;

    .page-card-header {
        display: flex;
        align-items: center;
        font-weight: bold;
        flex-shrink: 1;
        height: 30px;

        .page-card-header-right {
            display: flex;
            gap: 10px;
            margin-left: auto;
        }
    }

    .page-card-body {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        gap: 10px;

        .page-card-block-value {
            display: flex;
            gap: 5px;

            .page-card-label {
                font-weight: 600;
                color: black;
                white-space: nowrap;
            }

            &:has(.page-card-value[type="textarea"]) {
                flex-direction: column;
            }

            .page-card-value {
                display: flex;
                align-items: center;
                color: #6e6e6e;

                &[type="price"],
                &[type="price_percent"] {
                    white-space: nowrap;
                    margin-left: auto;
                    text-align: right;
                    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
                }

                &[type="textarea"] {
                    width: 100%;
                    border: 1px solid #dee2e6;
                    border-radius: 5px;
                    padding: 10px;
                    white-space: pre-line;
                }
            }
        }
    }
}

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
    .page-card {
        font-size: 0.8rem !important;

        .page-card-block-value {
            flex-direction: column;
        }
    }
}
.page-tabs-card {

    display: grid !important;
    grid-template-columns: calc(18px + 10*2px + 30px) auto;
    transition: grid-template-columns 0.5s;
    gap: 10px;



    &:has(ap-page-tabs-menu.active) {
        grid-template-columns: 15% auto;
    }

    ap-page-tabs-menu {
        display: flex;
        flex-direction: column;

        scrollbar-gutter: stable both-edges;

        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #f7f7f7;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        gap: 10px;

        &.active {
            ap-page-tabs-menu-item {
                &.active {
                    &:after {
                        content: "›";
                        transform: scale(1.5);
                        font-weight: bold;
                        position: absolute;
                        right: 4px;
                        background-color: #3475f8;
                        color: white;
                        width: 12px;
                        padding-left: 4px;
                        height: 14px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        ap-page-tabs-menu-item {
            color: #6e6e6e;
            display: grid;
            align-items: center;
            grid-template-columns: 20px auto;

            gap: 10px;
            padding: 10px;
            border-radius: 10px;

            position: relative;
            cursor: pointer;

            white-space: nowrap;

            ap-icon {
                font-size: 18px !important
            }

            &:hover {
                background-color: #77a5ff;
                color: white;
            }

            &.active {
                color: white;
                background-color: #3475f8;
            }

            .page-tabs-menu-item-bagde {
                position: absolute;
                top: 0;
                left: 30px;
                font-size: 0.75em;
                font-weight: 700;
            }
        }

        ap-page-tabs-menu-title {

            position: sticky;
            top: 0;

            background-color: inherit;
            color: #6e6e6e;

            display: grid;
            align-items: center;
            grid-template-columns: 20px auto;
            font-weight: bold;

            gap: 10px;
            padding: 10px;

            z-index: 1;

            font-size: 1rem;

            cursor: pointer;

            white-space: nowrap;

            ap-icon {
                font-size: 1.3rem !important
            }
        }

    }

    ap-page-tabs-menu-content {
        height: 100%;
        overflow: hidden;
    }


}

.page-tabs-container {
    height: 100%;
    display: none;
    grid-template-rows: 47px auto;
    gap: 0.5rem;
    overflow: hidden;

    &.active {
        display: grid;
    }

    &.no-title {
        grid-template-rows: 0 auto !important;

        .page-tabs-title {
            visibility: hidden;
        }
    }

    .page-tabs-title {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1.5rem;
        color: black;
        border-bottom: 1px solid #dee2e6;

        .page-tabs-title-right {
            display: flex;
            gap: 10px;
            margin-left: auto;
            font-weight: normal;
        }
    }

    .page-tabs-content {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;

        .page-tabs-content-subtitle {
            font-weight: bold;
        }

    }

}
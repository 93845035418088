.edit-group-quote {

    .modal-header {
        gap: 10px;


        #infos_quote {
            flex-grow: 1;
            display: flex;
            height: 100%;
            align-items: center;
            white-space: nowrap;
        }

        .modal-title-right {
            white-space: nowrap;
            color: #6c757d;
            gap: 10px;
            display: flex;

            #oldPrice {
                color: #03a9f4;
            }

            .line {
                border-right: 1px solid #D9D9D9;
            }

            #newPrice {
                color: #43a047;
            }
        }
    }

}
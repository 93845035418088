ap-alert {

    overflow: hidden;
    display: block;

    height: 0;
    transition: height 0.5s ease-out;

    --border-radius: 2px;

    .content {

        padding: 16px;
        width: 100%;

        background-color: var(--background-color);

        border: 1px solid var(--border-color);
        border-radius: var(--border-radius);

        color: var(--color);

        .title {
            font-weight: bold;
        }

    }

    &.visible {
        height: var(--calc-height);
        transition: height 0.5s ease-in;
    }

    &[type="warning"] {
        --background-color: #FFF3CD;
        --border-color: #FFEEBA;
        --color: #856404;
    }

}
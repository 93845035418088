core-aside {
    top: calc(2 * var(--ap-navbar-height)) !important;
    height: calc(100% - 2 * var(--ap-navbar-height)) !important;

    box-shadow: none !important;
}

#appName {
    margin-left: calc(-1 * var(--ap-aside-width));
}

core-aside-toggler {
    position: absolute;
    top: var(--ap-navbar-height);
    box-shadow: none !important;

    .aside-toggler-label {
        border-top: 1px solid #f2f1f7;
        font-size: 14px !important;
        padding-left: 7px;
        color: rgb(255 255 255 / 41%) !important;
    }

    .icon {
        box-shadow: none !important;
        border-top: 1px solid #f2f1f7;

        &:before {
            content: '';
            height: 100000px;
            position: absolute;
            top: 0;
            right: -6px;
            background: linear-gradient(90deg, rgb(0 0 0 / 27%) 0%, rgb(0 0 0 / 0%) 100%);
            width: 6px;
        }
    }
}

#aside {
    .aside-nav {
        a {
            cursor: pointer;
        }

        .separator {
            margin-left: 0.7rem;
            margin-right: 0.7rem;

            transition: margin-left 0.1s, margin-right 0.1s;

            &::before,
            &::after {
                min-width: 5.25rem !important;
            }

            .label {
                font-size: 1.1rem;
                margin-right: 1.5rem !important;
                margin-left: 1.5rem !important;
            }
        }
    }

    /*Correction pb du core qui ne met pas tout le temps la class hover*/
    aside-navigation-link {
        &:hover {
            >a {
                background-color: var(--ap-aside-link-hover-background) !important;
                font-weight: var(--ap-aside-link-hover-font-weight) !important;
                color: var(--ap-aside-link-hover-color) !important;
            }
        }
    }
}
label {
    &.switch {
        margin-bottom: 0 !important;
    }
}

.switch {
    position: relative;
    display: flex;
    width: 40px;
    height: 22px;

    input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        box-shadow: 0px 0px 0px 1px #ccc;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 3px;
            bottom: 3px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

    }

    input:checked+.slider {
        background-color: #2196F3;
        box-shadow: 0px 0px 0px 1px #303030;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

}




ap-switch-3 {

    margin-left: auto !important;

    .theme-toggle {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-color: #ccc;
        border-radius: 34px;
        padding: 1px;
    }

    .custom-radio-button {

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;

        input {
            display: none;
        }

        .checkmark {
            opacity: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: white;
            transition: opacity 0.3s ease;
            cursor: pointer;
        }

        input#first+.checkmark {
            background-color: var(--ap-red-400);
        }

        input#third+.checkmark {
            background-color: var(--ap-green-400);
        }

        input:checked+.checkmark {
            opacity: 1;
            display: inline-block;
        }

    }


}
.productCellRenderer {

    display: flex;
    align-items: center;
    gap: 10px;

    &.no-image {
        .image {
            display: none;
        }
    }

    .image {
        height: 34px;
        width: 34px;
        background: white;
        padding: 2px;
        border-radius: 50%;
        border: 1px solid #dddddd;
        cursor: pointer;
    }

}

.ag-cell {
    &:has(.productCellRenderer) {
        display: flex;
        align-items: center;
    }
}
ap-button {

    display: flex;
    align-items: center;
    justify-content: center;

    max-width: fit-content;

    font-size: 15px;
    padding: 5px 1rem;
    border-radius: var(--border-radius, 8px);
    gap: 0.5rem !important;

    background-color: var(--background);
    border: 1px solid var(--border-color, transparent);
    color: var(--color);

    cursor: pointer;

    &:not(.disabled) {
        &:hover {
            background-color: var(--hover-background);
            border: 1px solid var(--hover-border-color, transparent);
            color: var(--hover-color);
        }

        &:active {
            background-color: var(--active-background) !important;
            border: 1px solid var(--active-border-color, transparent) !important;
            color: var(--active-color) !important;
        }
    }

    &.disabled {
        cursor: not-allowed;
        background-color: var(--disabled-background);
        border: 1px solid var(--disabled-border-color, transparent);
        color: var(--disabled-color);
        opacity: 0.3;
    }

    &.btn-line {
        --border-color: var(--color) !important;
        --active-border-color: var(--active-color) !important;
        --hover-border-color: var(--hover-color) !important;
        --disabled-border-color: var(--disabled-color) !important;
    }

    ap-icon {
        font-size: 18px;
    }

    &.btn-validate {
        --background: #5e35b1;
        --color: white;

        --hover-background: #4b229e;
        --hover-color: white;

        --active-background: #451c98;
        --active-color: white;
    }

    &.btn-close {
        --background: transparent;
        --color: black;
        --border-color: transparent;

        --hover-background: rgba(97, 97, 97, 0.2);
        --hover-color: black;

        --active-background: rgba(97, 97, 97, 0.2);
        --active-color: black;
    }

    &.btn-save {
        --background: #43a047;
        --color: white;

        --hover-background: #308d34;
        --hover-color: white;

        --active-background: #2a872e;
        --active-color: white;

        --disabled-background: #B4B4B4;
        --disabled-color: white;
    }


    &.btn-apply {
        --background: #1A183F;
        --color: white;

        --hover-background: #28264F;
        --hover-color: white;

        --active-background: #2a2766;
        --active-color: white;

        --disabled-background: #1A183F;
        --disabled-color: white;
    }

    &.btn-defaultValue {
        --background: #1A183F;
        --color: white;

        --hover-background: #28264F;
        --hover-color: white;

        --active-background: #2a2766;
        --active-color: white;

        --disabled-background: #1A183F;
        --disabled-color: white;
    }


    /*&.btn-add {
        --background: white;
        --color: #B4B4B4;
        --border-color: #B4B4B4;

        --hover-background: #B4B4B4;
        --hover-color: white;
        --hover-border-color: #B4B4B4;

        --active-background: #B4B4B4;
        --active-color: white;
        --active-border-color: #B4B4B4;
    }*/

    &.btn-add {
        --background: transparent;
        --color: #43a047;

        --hover-background: rgba(43, 102, 46, 0.1);
        --hover-color: #43a047;

        --active-background: rgba(43, 102, 46, 0.1);
        --active-color: #43a047;
    }

    &.btn-reload,
    &.btn-recap,
    &.btn-open,
    &.btn-export,
    &.btn-default {
        --background: transparent;
        --color: black;

        --hover-background: rgba(0, 0, 0, 0.1);
        --hover-color: black;

        --active-background: rgba(0, 0, 0, 0.1);
        --active-color: black;
    }

    &.btn-action,
    &.btn-edit {
        --background: transparent;
        --color: #3475F8;

        --hover-background: rgba(52, 117, 248, 0.1);
        --hover-color: #3475F8;

        --active-background: rgba(52, 117, 248, 0.1);
        --active-color: #3475F8;
    }

    &.btn-delete {
        --background: transparent;
        --color: #d32f2f;

        --hover-background: rgba(192, 28, 28, 0.1);
        --hover-color: #d32f2f;

        --active-background: rgba(192, 28, 28, 0.1);
        --active-color: #d32f2f;
    }

    &.btn-settings {
        --background: #e0e0e0;
        --color: black;

        --hover-background: #cdcdcd;
        --hover-color: black;

        --active-background: #c7c7c7;
        --active-color: black;
    }

    &.btn-duplicate {
        --background: transparent;
        --color: #5e35b1;

        --hover-background: rgba(69, 28, 152, 0.1);
        --hover-color: #5e35b1;

        --active-background: rgba(69, 28, 152, 0.1);
        --active-color: #5e35b1;
    }

    &.btn-print {
        --background: transparent;
        --color: #1F35DC;

        --hover-background: rgba(52, 117, 248, 0.1);
        --hover-color: #1F35DC;

        --active-background: rgba(52, 117, 248, 0.1);
        --active-color: #1F35DC;
    }


    &.btn-navbar,
    &.btn-navbar-text {
        height: 42px;

        --background: transparent;
        --color: white;

        --hover-background: rgba(255, 255, 255, 0.50);
        --hover-color: white;

        --active-background: rgba(255, 255, 255, 0.50);
        --active-color: white;
    }

    &.btn-navbar {
        width: 42px;
        padding: 0;
        max-width: inherit;
        --border-radius: 50%;
    }

    &.btn-action-aggrid,
    &.btn-icon {
        padding: 0;
        max-width: inherit;
        border-radius: 50%;

        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
    }

}
#navbar {
    .navbar-brand {

        display: flex;
        align-items: center;

        #navbar-brand-logo {
            margin-left: 2px;
            margin-right: 10px;
            width: 30px;
            height: 30px;
            max-width: 30px;
            max-height: 30px;
        }

        #navbar-brand-name {
            font-size: 22px;
        }
    }
}


form {
    //Créer une variable CSS pour les couleurs
        --color-form-invalid: #EA5757;

    &.submitted {

        .ap-input,
        .ap-textarea,
        ap-select {
            &:invalid {
                border-color: var(--color-form-invalid);

                &+.invalid-feedback {
                    display: block;
                    color: var(--color-form-invalid);
                }

                &+.suffix {
                    display: block;
                    color: var(--color-form-invalid);
                }
            }


            &:valid {
                +.invalid-feedback {
                    display: none;
                }
            }
        }

        ap-input-addon  {

            input {
                &:invalid {
                    --border-color: var(--color-form-invalid);
    
                    &+.invalid-feedback {
                        display: block;
                        color: var(--color-form-invalid);
                    }
    
                    &+.suffix {
                        border-color: var(--color-form-invalid);
                    }
                }
            }

            &.invalid {
                &+.invalid-feedback {
                    display: block;
                    color: var(--color-form-invalid);
                }
            }

            &.valid {
                +.invalid-feedback {
                    display: none;
                }
            }
           
        }

        ap-select {
            &.invalid {

                --ap-select-border-color: var(--color-form-invalid);

                &+.invalid-feedback {
                    display: block;
                    color: var(--color-form-invalid);
                }
            }

            &:not(.invalid) {
                +.invalid-feedback {
                    display: none;
                }
            }
        }

    }
}
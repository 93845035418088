.page-tabs-container-print {

    position: relative;

    display: grid !important;
    grid-template-columns: 20% auto;
    gap: 10px;

    width: 100%;
    height: 100%;
    overflow: hidden;

    &.no-apply-button {
        .container-apply-button {
            grid-template-columns: auto;

            .btn-defaultValue {
                display: none;
            }
        }
    }

    &.is-default {
        .col-form {
            grid-template-rows: 1fr;

            .container-apply-button {
                display: none !important;
            }
        }
    }

    &.no-form-template {

        grid-template-columns: auto;

        .col-form {
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 1;
            grid-template-rows: 30px;
            width: 180px;
            height: min-content;

            .container-apply-button {
                grid-template-columns: auto;

                ap-button {
                    &.btn-defaultValue {
                        display: none;
                    }
                }
            }

            .container-form {
                display: none;
            }
        }
    }

    .col-form {

        display: grid;
        grid-template-rows: 30px 1fr;
        gap: 10px;
        overflow: hidden;

        height: 100%;
        width: 100%;

        .container-apply-button {
            display: grid;
            grid-template-columns: auto 50px;

            ap-button {
                width: 100%;
                max-width: 100%;

                button {
                    width: 100%;
                    padding: 0;
                }
            }

            ap-button {

                &.btn-defaultValue {
                    margin-left: -10px;
                    padding: 0 0 0 5px;
                }

                &.btn-apply {
                    z-index: 1;
                    padding-left: 0px;
                    padding-right: 0px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

        }

        .container-form {
            display: flex;
            flex-direction: column;
            gap: 10px;

            height: 100%;
            overflow: auto;
            min-height: 0;

            .card-print {
                border-radius: 10px;
                border: 1px solid rgba(0, 0, 0, 0.125);
                padding: 10px;
                display: flex;
                flex-direction: column;

                .card-print-title {
                    margin-bottom: 10px;
                    font-weight: bold;

                    +.card-print-body {
                        margin-left: 10px;
                    }
                }

                .card-print-body {
                    display: flex;
                    flex-direction: column;
                }

                .input-group {
                    &:has(input[type="color"]) {

                        display: flex;
                        align-items: center;
                        gap: 5px;

                        label {
                            margin-bottom: 0;
                        }

                        input[type="color"] {
                            appearance: none;
                            width: 30px;
                            height: 20px;
                            background-color: transparent;
                            cursor: pointer;
                            border: none;

                            &::-webkit-color-swatch-wrapper {
                                padding: 0;
                            }

                            &::-webkit-color-swatch {
                                border-radius: 5px;
                                border: 1px solid #ADB5BD;
                            }


                            &::-moz-color-swatch {
                                border-radius: 5px;
                                border: 1px solid #ADB5BD;
                            }
                        }

                    }
                }


            }
        }
    }

    .col-fake-print {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        iframe {
            height: 100%;
            width: 100%;
            border: 0;
        }

        .loader-fake-print {
            position: absolute;
            top: 0;
            left: 0;

            height: 100%;
            width: 100%;

            opacity: 0;
            z-index: 9999;
            transition: opacity 0.2s;

            .container-spinner {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                background: #9e9e9e;

                ap-icon {
                    color: white
                }
            }
        }
    }

}
:root {
	--ap-aside-background: #363a45;
	--ap-navbar-background: #363a45;
	--ap-page-title-background: #3475f8;
	--ap-cover-page-aside-background: #3475f8;
	--ap-cover-page-background: #3475f8;
	--AppColorBackground: #F2F1F7;
}

:root .is-admin {
	--ap-aside-background: #3f51b5;
	--ap-navbar-background: #3f51b5;
	--ap-page-title-background: #363a45;
	--ap-cover-page-aside-background: #363a45;
	--ap-cover-page-background: #363a45;
}

#login-btn {
	--background: #3475f8;
	--color: white;
	--border-color: white;
	--hover-background: #1762fa;
	--hover-color: white;
	--active-background: #1762fa;
	--active-color: white;
}

#login-form {
	height: 100%;
	justify-content: center;
}

.dropdown-item {
	display: flex;
	align-items: center;
	cursor: pointer;

	ap-icon {
		margin-right: 0.5rem;
	}
}

.modal {
	&.modal-static {
		.modal-dialog {
			transform: none !important;
		}
	}
}

button {
	outline: none !important;
}

.btn-xs {
	--size: 2.285em !important;
	font-size: 0.8em !important;
}

.dropdown-item {
	&.disabled {
		color: #6c757d !important;
	}
}

.tabs-container {

	align-items: end;
	overflow: visible;

	.btn-tab {
		border-radius: 0;

		box-shadow: none;
		background-color: transparent;
		color: white;
		position: relative;
		margin-left: -15px;

		transition: none;

		border-top-left-radius: 20px;
		border-top-right-radius: 20px;

		&:not(.tab-active) {

			&:active,
			&:hover {
				background-color: rgb(241 245 248 / 22%) !important;
				box-shadow: none !important;

				&::before,
				&::after {
					opacity: 0.2;
				}
			}
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			height: 100%;
			width: 25px;
			top: 0px;
			pointer-events: none;
			opacity: 0;
			box-shadow: 0px 15px 0 0 var(--AppColorBackground);
		}

		&::before {
			left: -25px;
			border-bottom-right-radius: 15px;
		}

		&::after {
			right: -25px;
			border-bottom-left-radius: 15px;
		}

		&.btn-tab:has(+ .tab-active) {
			border-top-right-radius: 0px;
		}

		&.tab-active {
			background-color: var(--AppColorBackground);
			color: black;

			z-index: 900;

			&:active,
			&:hover {
				opacity: 1;
				box-shadow: none !important;
			}

			&::before,
			&::after {
				opacity: 1;
			}

			&+.btn-tab {
				border-top-left-radius: 0px;
			}
		}
	}

}

#app-content {

	background-color: var(--AppColorBackground);

	&:not(:has(.cover-page)) {
		position: relative;
	}

	#page-title {
		.title {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}


	overflow: hidden;
}

#app {
	&.no-navbar {
		padding-top: 0;
	}
}

.navbar-bottom {
	background: white;
}

.slimscroll-container {
	overflow: auto;
}
.mobile {
    ap-company-select {

        #selected-company-name {
            display: none;
        }

        .custom-nav-select {
            color: black;

            &#single-company {
                display: none;
            }
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            margin-top: -12px;
        }
    }
}

ap-company-select {

    // Pour empêcher la selection de texte 
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .custom-nav-select {
        display: flex;
        align-items: center;
        margin-left: 5px;
        background: none !important;
        border: none !important;
        color: white;
        border-radius: 8px;

        &#no-company,
        &#single-company {
            display: flex;
            gap: 0.5rem;
            padding-left: 0.9rem;
            padding-right: 0.9rem;
            height: 42px;
        }

        &#no-company {
            opacity: 0.5;
        }

        &#company-dropdown {

            cursor: pointer;

            &:hover {
                background-color: rgba(255, 255, 255, 0.5) !important;
            }

            &:active {
                background-color: white !important;
                color: black;
            }

        }
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding-left: 0.9rem;
        padding-right: 0.9rem;
        height: 42px;

        ap-icon {
            display: flex;
            font-size: 18px;
        }
    }

    .dropdown-menu {
        background: #363A45;
        border-radius: 10px;

        .dropdown-item {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            color: white;

            &:hover {
                background-color: rgba(255, 255, 255, 0.5) !important;
            }

            &:active {
                background-color: white !important;
                color: black;
            }

            &.active {
                background-color: #ffffff3d;
            }

        }
    }

    .company-logo {
        img {
            max-width: 32px;
            max-height: 32px;
        }
    }

}